import Artist from '@root/models/artist';

export default class Song {
  constructor(props) {
    this.album = props.album;
    this.artists = props.artists.map((artist) => new Artist(artist));
    this.externalUrls = props.externalUrls;
    this.href = props.href;
    this.id = props.id;
    this.name = props.name;
    this.popularity = props.popularity;
    this.uri = props.uri;
  }

  static buildSongListFromData(data) {
    return data.items.map((item) => new Song(item));
  }
}