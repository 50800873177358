import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { StyleSheet } from '@root/utils/styles';
import { initializeApp } from 'firebase/app';
import { logEvent, logView } from '@root/utils/analytics';
import { poppinsMedium } from '@root/utils/fonts';
import AuthService from '@root/utils/auth-service';
import Footer from '@root/components/footer';
import LoginRedesign from '@root/components/login-redesign';
import NavPills from '@root/components/nav-pills';
import NowPlaying from '@root/components/now-playing';
import React, { useState } from 'react';
import RoutingService from '@root/utils/routing-service';
import TopArtists from '@root/components/top-artists';
import TopArtistsDetails from '@root/components/top-artists-details';
import TopSongs from '@root/components/top-songs';
import TopSongsDetails from '@root/components/top-songs-details';

const firebaseConfig = {
  apiKey: 'AIzaSyCfrxmg7rZ2q_avThHbKlJ_fcSUzO5WPfo',
  authDomain: 'spotifydashboard.firebaseapp.com',
  projectId: 'spotifydashboard',
  storageBucket: 'spotifydashboard.appspot.com',
  messagingSenderId: '437154727297',
  appId: '1:437154727297:web:b8fac2942b65b0030c5480',
  measurementId: 'G-8P9HR0HYSE',
};

initializeApp(firebaseConfig);

export default function AppEntry() {
  const authToken = AuthService.getAuthToken();

  const [isLoggedIn, setIsLoggedIn] = useState(!!authToken);
  const [networkError, setNetworkError] = useState();

  const navigate = useNavigate();

  logView('app_entry');

  const handleNetworkError = (error) => {
    logEvent('app_entry_network_error_shown');
    setNetworkError(error);
  };

  const handleLogout = () => {
    logEvent('app_entry_logout_clicked');
    setIsLoggedIn(false);
    setNetworkError(null);
    navigate('/');
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate(RoutingService.routes[Object.keys(RoutingService.routes)[0]].route, { replace: true });
  };

  const sceneProps = {
    onNetworkError: handleNetworkError,
    shouldRefetch: true,
  };

  const defaultPath = RoutingService.routes[Object.keys(RoutingService.routes)[0]].path;

  return (
    <>
      {!isLoggedIn && (
        <div css={styles.loginContainer}>
          <h1
            css={styles.header}
          >
            TunePrefs
          </h1>
          <LoginRedesign
            error={networkError}
            onLogin={handleLogin}
            onLogout={handleLogout}
          />
          <Footer />
        </div>
      )}
      {isLoggedIn && (
        <>
          <h1
            css={styles.header}
          >
            TunePrefs
          </h1>
          <LoginRedesign
            error={networkError}
            onLogin={handleLogin}
            onLogout={handleLogout}
          />
          <NavPills />
          <Routes>
            <Route
              element={<NowPlaying {...sceneProps} />}
              path={RoutingService.routes.nowPlaying.path}
            />
            <Route
              element={<TopArtistsDetails {...sceneProps} />}
              path={RoutingService.routes.topArtistsDetails.path}
            />
            <Route
              element={<TopSongsDetails {...sceneProps} />}
              path={RoutingService.routes.topSongsDetails.path}
            />
            <Route
              element={<TopArtists {...sceneProps} />}
              path={RoutingService.routes.topArtists.path}
            />
            <Route
              element={<TopSongs {...sceneProps} />}
              path={RoutingService.routes.topSongs.path}
            />
            <Route
              element={<Navigate replace to={defaultPath}/>}
              path={'*'}
            />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    color: 'rgb(33, 37, 41)',
    letterSpacing: '-0.05rem',
    fontSize: 40,
    textAlign: 'center',
    marginTop: 10,
    ...poppinsMedium(),
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
  },
});