import Recase from 'recase';

const recase = Recase.create({});

export default class NetworkRequestController {
  constructor(authToken) {
    this.authToken = authToken;
  }

  async _getData(response){
    try {
      return recase.camelCopy(await response.json());
    } catch (e) {
      return {};
    }
  }

  async request(config) {
    const { method, body, url } = config;

    let response, data, error;

    try{
      response = await fetch(url, {
        method,
        body,
        headers: {
          'Authorization': `Bearer ${this.authToken}`,
        },
      });

      data = await this._getData(response);
    } catch (e) {
      error = e;
    }

    if(error) {
      throw error;
    }

    return data;
  }
}