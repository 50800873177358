import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';
import React from 'react';

export default function PopularityBadge({ popularity }) {
  const variant = () => {
    if (popularity < 60) {
      return 'primary';
    } else if (popularity < 70) {
      return 'info';
    } else if (popularity < 80) {
      return 'success';
    } else if(popularity < 90) {
      return 'warning';
    }
    return 'danger';
  };

  return (
    <Badge
      pill
      bg={variant()}
      text={'light'}
    >
      {popularity}
    </Badge>
  );
}

PopularityBadge.propTypes = {
  popularity: PropTypes.number.isRequired,
};