import { StyleSheet } from '@root/utils/styles';
import { isMobile } from '@root/utils/browser';
import { logEvent, logView } from '@root/utils/analytics';
import { topSongsConfiguration } from '@root/api/spotify-configuration';
import AuthService from '@root/utils/auth-service';
import Card from 'react-bootstrap/Card';
import NetworkRequestController from '@root/utils/network-request-controller';
import PlaylistExportButton from '@root/components/playlist-export-button';
import PopularityBadge from '@root/components/popularity-badge';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import ShareModal, { shareModalTypes } from '@root/components/share-modal';
import Song from '@root/models/song';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import TimeRangeTabs from '@root/components/time-range-tabs';
import TimeRanges from '@root/models/time-ranges';

export default function TopSongsDetails({ onNetworkError }) {
  logView('top_songs_details');

  const authToken = AuthService.getAuthToken();

  const [topSongs, setTopSongs] = useState();
  const [isFetching, setIsFetching] = useState();
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [timeRange, setTimeRange] = useState(TimeRanges.ranges.mediumTerm);
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);

  const handleGetTopSongs = async () => {
    if(authToken && !hasNetworkError && !topSongs && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(topSongsConfiguration(timeRange.queryParam));

      if(data.error) {
        setHasNetworkError(true);
        onNetworkError(data.error);
        return;
      }

      parseTopSongData(data);
      setIsFetching(false);
    }
  };

  const parseTopSongData = (data) => {
    const songList = Song.buildSongListFromData(data);

    setTopSongs(songList);
  };

  const handleUpdateTimeRange = (timeRangeKey) => {
    logEvent(`top_songs_details_${timeRangeKey}_clicked`);
    setTimeRange(TimeRanges.ranges[timeRangeKey]);
    setTopSongs(null);
  };

  const handleShareClick = () => {
    logEvent('top_songs_details_share_clicked');

    setIsShowingShareModal(true);
  };


  useEffect(() => {
    handleGetTopSongs();
  }, [handleGetTopSongs, authToken, timeRange]);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title className={'mb-3'}>Your top {topSongs ? `${topSongs.length} ` : ''}songs {timeRange.description}</Card.Title>
          {!topSongs && !hasNetworkError && (
            <Row className={'justify-content-center'}>
              <Spinner animation={'border'} />
            </Row>
          )}
          {topSongs && (
            <>
              <TimeRangeTabs
                onChange={(key) => handleUpdateTimeRange(key)}
                onClickShare={handleShareClick}
                selectedTimeRange={timeRange}
              >
                <PlaylistExportButton
                  selectedTimeRange={timeRange}
                  songs={topSongs}
                />
              </TimeRangeTabs>
              <Row>
                <Table
                  css={styles.table}
                  responsive={'md'}
                  size={isMobile() ? 'sm' : 'lg'}
                >
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Artist</th>
                      <th>Album</th>
                      <th>Album Art</th>
                      <th>Popularity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topSongs.map((song, idx) => (
                      <tr key={idx}>
                        <td>{idx+1}</td>
                        <td>{song.name}</td>
                        <td>{song.artists.map((artist) => artist.name).join(', ')}</td>
                        <td>{song.album.name}</td>
                        <td><img src={song.album.images[0]?.url} width={isMobile() ? '75' : '100'} /></td>
                        <td>
                          <PopularityBadge
                            popularity={song.popularity}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
      {isShowingShareModal && (
        <ShareModal
          onHide={() => setIsShowingShareModal(false)}
          timeRange={timeRange}
          top={topSongs}
          type={shareModalTypes.song}
        />
      )}
    </>
  );
}

TopSongsDetails.propTypes = {
  onNetworkError: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  table: {
    fontSize: isMobile() ? 14 : 16,
  },
});