import { StyleSheet } from '@root/utils/styles';
import { getColorsFromPalette } from '@root/utils/colors';
import { poppinsMedium } from '@root/utils/fonts';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

export default function ColorCard({
  colorThief,
  rank,
  title,
  subtitle,
  imageUrl,
}) {
  const imageRef = useRef();

  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();

  const handleImageLoaded = () => {
    const img = imageRef.current;

    if(img.complete){
      const dominantColor = colorThief.getColor(img);
      const colorPalette = colorThief.getPalette(img);
      const colors = getColorsFromPalette([dominantColor, ...colorPalette]);

      setBackgroundColor(colors.backgroundColor);
      setTextColor(colors.textColor);
    }
  };

  const colorStyles = () => {
    if(!backgroundColor || !textColor) {
      return;
    }

    return StyleSheet.create({
      container: {
        backgroundColor: `rgba(${backgroundColor[0]}, ${backgroundColor[1]}, ${backgroundColor[2]})`,
        color: `rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]})`,
      },
    }).container;
  };

  const rankString = `${rank < 10 ? '0' : ''}${rank}`;

  const rankStyles = () => {
    if(!backgroundColor || !textColor) {
      return;
    }

    return StyleSheet.create({
      rank: {
        borderBottom: `3px solid rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]})`,
      },
    }).rank;
  };

  return (
    <div css={[colorStyles(), styles.container]}>
      <div css={styles.textContainer}>
        <div css={[rankStyles(), styles.rank]}>{rankString}</div>
        <div css={styles.title}>{title}</div>
        {subtitle && <div css={styles.subtitle}>{subtitle}</div>}
      </div>
      <img
        alt={title}
        crossOrigin={'Anonymous'}
        css={styles.image}
        onLoad={handleImageLoaded}
        ref={imageRef}
        src={imageUrl}
      />
    </div>
  );
}

ColorCard.propTypes = {
  rank: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  colorThief: PropTypes.any,
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    '@media only screen and (max-width: 635px)': {
      maxWidth: '48%',
      margin: 2,
    },
    ...poppinsMedium(),
  },
  textContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  rank: {
    fontSize: 45,
    marginBottom: 6,
    letterSpacing: '-0.1rem',
  },
  title: {
    fontSize: 20,
    display: 'block',
    marginBottom: 6,
    letterSpacing: '-0.05rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: 16,
    display: 'block',
    marginBottom: 6,
    letterSpacing: '-0.05rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  image: {
    borderRadius: '0 0 10px 10px',
    width: 300,
    height: 300,
    objectFit: 'cover',
    objectPosition: '25% 25%',
    '@media only screen and (max-width: 635px)': {
      width: 'auto',
      height: 'auto',
      aspectRatio: '1',
    },
  },
});