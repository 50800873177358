import { PillButton } from '@root/components/pill';
import { StyleSheet } from '@root/utils/styles';
import { logEvent } from '@root/utils/analytics';
import { useLocation, useNavigate } from 'react-router-dom';
import NavSeparator from '@root/components/nav-separator';
import React from 'react';
import RoutingService from '@root/utils/routing-service';

export default function NavPills() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <NavSeparator />
      <div css={styles.pillContainer}>
        {Object.keys(RoutingService.routes).map((routeName, idx) => {
          const route = RoutingService.routes[routeName];
          const isActive = location.pathname == route.route;

          const handlePillClick = () => {
            logEvent(`nav_pills_${route.path}_clicked`);
            navigate(route.route);
          };

          return (
            <PillButton
              isActive={isActive}
              key={idx}
              onClick={handlePillClick}
            >
              {route.label}
            </PillButton>
          );
        })}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  pillContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 10,
  },
});