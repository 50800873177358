export default class Artist {
  constructor(props) {
    this.externalUrls = props.externalUrls;
    this.followers = props.followers;
    this.genres = props.genres;
    this.href = props.href;
    this.id = props.id;
    this.images = props.images;
    this.name = props.name;
    this.popularity = props.popularity;
    this.uri = props.uri;
  }

  static buildArtistListFromData(data) {
    return data.items.map((item) => new Artist(item));
  }
}