import '@fontsource/poppins/100-italic.css';
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200-italic.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300-italic.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700-italic.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800-italic.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900-italic.css';
import '@fontsource/poppins/900.css';

const fontFamily = '\'Poppins\', sans-serif';

export function poppinsLight() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
  };
}

export function poppinsLightItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 300,
  };
}

export function poppinsRegular() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
  };
}

export function poppinsRegularItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 400,
  };
}

export function poppinsMedium() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
  };
}

export function poppinsMediumItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 500,
  };
}

export function poppinsSemiBold() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
  };
}

export function poppinsSemiBoldItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 600,
  };
}

export function poppinsBold() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
  };
}

export function poppinsBoldItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 700,
  };
}

export function poppinsExtraBold() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
  };
}

export function poppinsExtraBoldItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 800,
  };
}

export function poppinsBlack() {
  return {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 900,
  };
}

export function poppinsBlackItalic() {
  return {
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 900,
  };
}