import { StyleSheet } from '@root/utils/styles';
import { poppinsMedium } from '@root/utils/fonts';
import React from 'react';

export default function NavSeparator() {
  return <div css={styles.loader}>Loading...</div>;
}

const styles = StyleSheet.create({
  loader: {
    ...poppinsMedium(),
  },
});