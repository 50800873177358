import { PillButton } from '@root/components/pill';
import { StyleSheet } from '@root/utils/styles';
import { exportComponentAsPNG } from 'react-component-export-image';
import { getColorsFromPalette } from '@root/utils/colors';
import { isIos } from '@root/utils/browser';
import { logEvent, logView } from '@root/utils/analytics';
import { poppinsBlack, poppinsBold } from '@root/utils/fonts';
import CloseButton from 'react-bootstrap/CloseButton';
import ColorThief from 'colorthief/dist/color-thief.umd.js';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

export const shareModalTypes = {
  song: 'song',
  artist: 'artist',
};

export default function ShareModal({ top, timeRange, onHide, type }) {
  logView('share_modal');

  const lengthOfShareList = top.length > 5 ? 5 : top.length;
  const shareList = top.slice(0, lengthOfShareList);

  const shareRef = useRef();
  const firstImageRef = useRef();

  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();

  const now = new Date();
  const nowString = `${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}-${now.getTime()}`;

  const colorThief = new ColorThief();

  const handleImageLoaded = () => {
    const img = firstImageRef.current;

    if(img.complete){
      const dominantColor = colorThief.getColor(img);
      const colorPalette = colorThief.getPalette(img);
      const colors = getColorsFromPalette([dominantColor, ...colorPalette]);

      setBackgroundColor(colors.backgroundColor);
      setTextColor(colors.textColor);
    }
  };

  const buildImage = (item, idx) => {
    let imageSrc;

    if (type === shareModalTypes.artist) {
      imageSrc = item.images[0]?.url;
    } else {
      imageSrc = item.album?.images[0]?.url;
    }

    return (
      <>
        {imageSrc && (
          <Image
            rounded
            alt={item.name}
            crossOrigin={idx === 0 ? 'Anonymous' : undefined}
            onLoad={idx === 0 ? handleImageLoaded : undefined}
            ref={idx === 0 ? firstImageRef : undefined}
            src={imageSrc}
            width='100'
          />
        )}
        <div css={styles.itemNumber}>{idx+1}</div>
      </>
    );
  };

  const buildItems = () => {
    return (
      <div>
        {shareList.map((item, idx) => (
          <div
            css={styles.item}
            key={idx}
          >
            {buildImage(item, idx)}
            <p css={textStyles()}>{`${item.name}${type === shareModalTypes.song ? ` - ${item.artists.map((artist) => artist.name).join(', ')}` : ''}`}</p>
          </div>
        ))}
      </div>
    );
  };

  const handleShare = () => {
    logEvent('share_modal_share_clicked');

    exportComponentAsPNG(shareRef, {
      fileName: `spotify-dashboard-top-${type}s-${nowString}`,
      html2CanvasOptions: { backgroundColor: null },
    });
  };

  const shareContainerStyles = () => {
    if(backgroundColor) {
      return StyleSheet.create({
        shareContainer: {
          backgroundColor: `rgba(${backgroundColor[0]}, ${backgroundColor[1]}, ${backgroundColor[2]})`,
        },
      }).shareContainer;
    }
    return null;
  };

  const textStyles = () => {
    if(textColor) {
      return StyleSheet.create({
        text: {
          color: `rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]})`,
        },
      }).text;
    }
    return null;
  };

  return (
    <Modal
      fullscreen={'true'}
      onHide={onHide}
      show={true}
    >
      <div className={'px-3 d-flex flex-row justify-content-between'}>
        <PillButton
          css={styles.button}
          onClick={handleShare}
        >
          Download to Share
        </PillButton>
        <CloseButton
          className={'mb-3 mt-3'}
          onClick={onHide}
        />
      </div>
      <div
        css={[styles.shareContainer, shareContainerStyles()]}
        ref={shareRef}
      >
        <p css={[styles.shareHeader, textStyles()]}>
          {`My top ${lengthOfShareList} ${type}s ${timeRange.description}:`}
        </p>
        {buildItems()}
        <p css={[styles.credit, textStyles()]}>Created with TunePrefs.com</p>
      </div>
    </Modal>
  );
}

ShareModal.propTypes = {
  type: PropTypes.oneOf([shareModalTypes.song, shareModalTypes.artist]).isRequired,
  top: PropTypes.array.isRequired,
  timeRange: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  shareContainer: {
    padding: 20,
    width: 500,
    height: 'auto',
    margin: '0 auto',
    marginBottom: 40,
    zoom: 0.5,
    borderRadius: 15,
  },
  shareHeader: {
    fontSize: isIos() ? 25 : 50,
    lineHeight: 1.1,
    ...poppinsBlack(),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    fontSize: isIos() ? 15 : 30,
    lineHeight: 1.1,
    ...poppinsBlack(),
  },
  itemNumber:{
    position: 'relative',
    right: 98,
    top: -35,
    color: 'white',
    textShadow: '2px 2px 5px rgba(33, 37, 41)',
    ...poppinsBold(),
  },
  modal: {
    backgroundColor: 'grey',
  },
  credit: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: isIos() ? 8 : 16,
    ...poppinsBold(),
  },
  button: {
    margin: '10px 0',
  },
});