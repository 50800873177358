import { StyleSheet } from '@root/utils/styles';
import { isMobile } from '@root/utils/browser';
import { logEvent, logView } from '@root/utils/analytics';
import { topArtistsConfiguration } from '@root/api/spotify-configuration';
import Artist from '@root/models/artist';
import AuthService from '@root/utils/auth-service';
import Card from 'react-bootstrap/Card';
import FollowButton from '@root/components/follow-button';
import Image from 'react-bootstrap/Image';
import NetworkRequestController from '@root/utils/network-request-controller';
import PopularityBadge from '@root/components/popularity-badge';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import ShareModal from '@root/components/share-modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import TimeRangeTabs from '@root/components/time-range-tabs';
import TimeRanges from '@root/models/time-ranges';

export default function TopArtistsDetails({ onNetworkError }) {
  logView('top_artists_details');

  const authToken = AuthService.getAuthToken();

  const [topArtists, setTopArtists] = useState();
  const [isFetching, setIsFetching] = useState();
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [timeRange, setTimeRange] = useState(TimeRanges.ranges.mediumTerm);
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);

  const handleGetTopArtists = async () => {
    if(authToken && !hasNetworkError && !topArtists && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(topArtistsConfiguration(timeRange.queryParam));

      if(data.error) {
        setHasNetworkError(true);
        onNetworkError(data.error);
        return;
      }

      parseTopArtistData(data);
      setIsFetching(false);
    }
  };

  const parseTopArtistData = (data) => {
    const artistList = Artist.buildArtistListFromData(data);

    setTopArtists(artistList);
  };

  const handleUpdateTimeRange = (timeRangeKey) => {
    logEvent(`top_artists_details_${timeRangeKey}_clicked`);
    setTimeRange(TimeRanges.ranges[timeRangeKey]);
    setTopArtists(null);
  };

  const handleShareClick = () => {
    logEvent('top_artists_details_share_clicked');

    setIsShowingShareModal(true);
  };

  useEffect(() => {
    handleGetTopArtists();
  }, [handleGetTopArtists, authToken, timeRange]);

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title className={'mb-3'}>Your top {topArtists ? `${topArtists.length} ` : ''}artists {timeRange.description}</Card.Title>
          {!topArtists && !hasNetworkError && (
            <Row className={'justify-content-center'}>
              <Spinner
                animation={'border'}
                data-testid={'loading'}
              />
            </Row>
          )}
          {topArtists && (
            <>
              <TimeRangeTabs
                onChange={(key) => handleUpdateTimeRange(key)}
                onClickShare={handleShareClick}
                selectedTimeRange={timeRange}
              />
              <Row>
                <Table
                  css={styles.table}
                  responsive={'md'}
                  size={isMobile() ? 'sm' : 'lg'}
                >
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Photo</th>
                      <th>Genres</th>
                      <th>Popularity</th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody>
                    {topArtists.map((artist, idx) => (
                      <tr key={idx}>
                        <td>{idx+1}</td>
                        <td>{artist.name}</td>
                        <td>
                          <Image
                            rounded
                            alt={artist.name}
                            src={artist.images[0]?.url}
                            width={isMobile() ? '75' : '100'}
                          />
                        </td>
                        <td>{artist.genres.join(', ')}</td>
                        <td>
                          <PopularityBadge
                            popularity={artist.popularity}
                          />
                        </td>
                        <td>
                          <FollowButton
                            artist={artist}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
      {isShowingShareModal && (
        <ShareModal
          onHide={() => setIsShowingShareModal(false)}
          timeRange={timeRange}
          top={topArtists}
          type={'artist'}
        />
      )}
    </>
  );
}

TopArtistsDetails.propTypes = {
  onNetworkError: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  table: {
    fontSize: isMobile() ? 14 : 16,
  },
});