export default class TimeRanges {
  static ranges = {
    shortTerm: {
      key: 'shortTerm',
      queryParam: 'short_term',
      description: 'over the past 4 weeks',
      title: 'Past 4 Weeks',
    },
    mediumTerm: {
      key: 'mediumTerm',
      queryParam: 'medium_term',
      description: 'over the past 6 months',
      title: 'Past 6 Months',
    },
    longTerm: {
      key: 'longTerm',
      queryParam: 'long_term',
      description: 'of all time',
      title: 'All Time',
    },
  };
}