const luminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export const getColorsFromPalette = (colorPalette) => {
  let bgColor, tColor;

  colorPalette.some((rgb1) => {
    if(bgColor && tColor) {
      return false;
    }

    return colorPalette.some((rgb2) => {
      if(rgb1 == rgb2) {
        return false;
      }

      if(bgColor && tColor) {
        return false;
      }

      if(contrast(rgb1, rgb2) >= 2.5) {
        bgColor = rgb1;
        tColor = rgb2;

        return true;
      }
    });
  });

  return {
    backgroundColor: bgColor,
    textColor: tColor,
  };
};