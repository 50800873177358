import { logEvent as firebaseLogEvent, getAnalytics } from 'firebase/analytics';
import { useEffect } from 'react';

export function logEvent(eventName, eventParams = {}) {
  const analytics = getAnalytics();

  firebaseLogEvent(analytics, eventName, eventParams);
}

export function logView(componentName) {
  const analytics = getAnalytics();
  const eventName = `${componentName}_viewed`;

  return useEffect(() => {
    firebaseLogEvent(analytics, eventName);
  }, []);
}