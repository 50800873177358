export default class RoutingService {
  static routes = {
    topArtists: {
      name: 'topArtists',
      label: 'Top Artists',
      route: '/top-artists',
      path: 'top-artists',
    },
    topSongs: {
      name: 'topSongs',
      label: 'Top Songs',
      route: '/top-songs',
      path: 'top-songs',
    },
    nowPlaying: {
      name: 'nowPlaying',
      label: 'Now Playing',
      route: '/now-playing',
      path: 'now-playing',
    },
    topArtistsDetails: {
      name: 'topArtistsDetails',
      label: 'Top Artist Details',
      route: '/top-artists-details',
      path: 'top-artists-details',
    },
    topSongsDetails: {
      name: 'topSongsDetails',
      label: 'Top Song Details',
      route: '/top-songs-details',
      path: 'top-songs-details',
    },
  }

  static getNameForRoute = (currentRoute) => {
    const activeRoute = Object.entries(this.routes).filter((routeDef) => {
      return routeDef[1].route == currentRoute;
    });
    if(!activeRoute.length) {
      return null;
    }

    return activeRoute[0][1]?.name;
  }
}