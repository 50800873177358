import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import React from 'react';
import ShareIconDeprecated from '@root/components/share-icon-deprecated';
import TimeRanges from '@root/models/time-ranges';

export default function TimeRangeTabs({ onChange, selectedTimeRange, onClickShare, children }) {
  return (
    <div>
      {Object.keys(TimeRanges.ranges).map((key, idx) => {
        const item = TimeRanges.ranges[key];

        return (
          <Button
            className={'me-3 mb-3'}
            key={idx}
            onClick={() => onChange(item.key)}
            variant={selectedTimeRange.key === item.key ? 'dark' : 'outline-dark'}
          >
            {item.title}
          </Button>
        );
      })}
      {onClickShare && (
        <Button
          className={'me-3 mb-3'}
          data-testid={'share-button'}
          onClick={onClickShare}
          variant={'outline-dark'}
        >
          <ShareIconDeprecated />
        </Button>
      )}
      {children}
    </div>
  );
}

TimeRangeTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClickShare: PropTypes.func,
  selectedTimeRange: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
};