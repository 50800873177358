const osStrings = {
  android: 'Android',
  iOS: 'iOS',
  other: 'Other',
};

const mobileOsStrings = [osStrings.android, osStrings.iOS];

export function getMobileOS() {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return osStrings.android;
  }
  else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return osStrings.iOS;
  }
  return osStrings.other;
}

export function isIos() {
  return getMobileOS() == osStrings.iOS;
}

export function isMobile() {
  return mobileOsStrings.includes(getMobileOS());
}