import { Modal } from 'react-bootstrap';
import { PillButton } from '@root/components/pill';
import { StyleSheet } from '@root/utils/styles';
import { logView } from '@root/utils/analytics';
import { poppinsMedium } from '@root/utils/fonts';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';
import React from 'react';

export default function NetworkErrorModal({ error, onClickLogIn }) {
  logView('network_error_modal');

  return (
    <Modal
      centered
      aria-labelledby={'contained-modal-title-vcenter'}
      backdrop={'static'}
      css={styles.modal}
      show={!!error}
      size={'md'}
    >
      <Modal.Header>
        <Modal.Title id={'contained-modal-title-vcenter'}>
          Your session has expired
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please log back in to Spotify
        </p>
        <Accordion defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header eventkey="1">
                Error details
            </Accordion.Header>
            <Accordion.Body eventkey="1">
              <p>Status code: {error?.status}</p>
              <p>Message: {error?.message}</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <PillButton
          onClick={onClickLogIn}
        >
          Return to login
        </PillButton>
      </Modal.Footer>
    </Modal>
  );
}

NetworkErrorModal.propTypes = {
  error: PropTypes.object,
  onClickLogIn: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  modal: {
    ...poppinsMedium(),
  },
});