import { StyleSheet } from '@root/utils/styles';
import { getColorsFromPalette } from '@root/utils/colors';
import { logView } from '@root/utils/analytics';
import { nowPlayingConfiguration } from '@root/api/spotify-configuration';
import { poppinsMedium } from '@root/utils/fonts';
import AuthService from '@root/utils/auth-service';
import ColorThief from 'colorthief/dist/color-thief.umd.js';
import NetworkRequestController from '@root/utils/network-request-controller';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function NowPlaying({ onNetworkError, shouldRefetch = false }) {
  logView('now_playing');

  const authToken = AuthService.getAuthToken();

  const imageRef = useRef();

  const colorThief = new ColorThief();

  const [song, setSong] = useState();
  const [nothingPlaying, setNothingPlaying] = useState(false);
  const [isFetching, setIsFetching] = useState();
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();

  const fetchNowPlayingInfo = useCallback(async () => {
    if(authToken && !hasNetworkError && !song && !nothingPlaying && !isFetching) {
      setIsFetching(true);
      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(nowPlayingConfiguration());

      if(data.error) {
        setHasNetworkError(true);
        onNetworkError(data.error);
        return;
      }

      if(data.item) {
        setSong(data.item);
      }

      if(!data.item) {
        setNothingPlaying(true);
      }

      setIsFetching(false);
    }
  }, []);

  const handleImageLoaded = () => {
    const img = imageRef.current;

    if(img.complete){
      const dominantColor = colorThief.getColor(img);
      const colorPalette = colorThief.getPalette(img);
      const colors = getColorsFromPalette([dominantColor, ...colorPalette]);

      setBackgroundColor(colors.backgroundColor);
      setTextColor(colors.textColor);
    }
  };

  const colorStyles = () => {
    if(!backgroundColor || !textColor) {
      return;
    }

    return StyleSheet.create({
      container: {
        backgroundColor: `rgba(${backgroundColor[0]}, ${backgroundColor[1]}, ${backgroundColor[2]})`,
        color: `rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]})`,
      },
    }).container;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(shouldRefetch){
        fetchNowPlayingInfo();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchNowPlayingInfo]);

  useEffect(() => {
    fetchNowPlayingInfo();
  }, [fetchNowPlayingInfo]);

  const imageUrl = song?.album.images[0].url;
  const artist = song?.artists[0].name;
  const songName = song?.name;
  const albumName = song?.album.name;

  return (
    <div css={styles.outerContainer}>
      {isFetching && <span aria-label='Loading' />}
      {song && (
        <div css={[colorStyles(), styles.container]}>
          <div css={styles.textContainer}>
            <div css={styles.title}>{songName}</div>
            <div css={styles.subtitle}>{artist}</div>
            <div css={styles.subtitle}>{albumName}</div>
          </div>
          <img
            alt={`${songName} - ${artist}`}
            crossOrigin={'Anonymous'}
            css={styles.image}
            onLoad={handleImageLoaded}
            ref={imageRef}
            src={imageUrl}
          />
        </div>
      )}
      {nothingPlaying && !song && (
        <div css={styles.nothingPlaying}>Nothing is playing currently.</div>
      )}
    </div>
  );
}

NowPlaying.propTypes = {
  onNetworkError: PropTypes.func.isRequired,
  shouldRefetch: PropTypes.bool,
};

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...poppinsMedium(),
  },
  container: {
    borderRadius: 10,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    '@media only screen and (max-width: 635px)': {
      maxWidth: '49%',
      margin: 2,
    },
  },
  nothingPlaying: {
    fontSize: 25,
    marginTop: 20,
    marginBottom: 20,
  },
  textContainer: {
    padding: 15,
  },
  title: {
    fontSize: 28,
    display: 'block',
    marginBottom: 6,
    letterSpacing: '-0.05rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: 16,
    display: 'block',
    marginBottom: 6,
    letterSpacing: '-0.05rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  image: {
    borderRadius: '0 0 10px 10px',
    width: 'auto',
    height: 'auto',
    aspectRatio: '1',
    objectFit: 'cover',
  },
});