import { StyleSheet } from '@root/utils/styles';
import { logEvent, logView } from '@root/utils/analytics';
import { topArtistsConfiguration } from '@root/api/spotify-configuration';
import Artist from '@root/models/artist';
import AuthService from '@root/utils/auth-service';
import ColorCard from '@root/components/color-card';
import ColorThief from 'colorthief/dist/color-thief.umd.js';
import Loader from '@root/components/loader';
import NetworkRequestController from '@root/utils/network-request-controller';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ShareModal from '@root/components/share-modal';
import TimeRangePills from '@root/components/time-range-pills';
import TimeRanges from '@root/models/time-ranges';

export default function TopArtists({ onNetworkError }) {
  logView('top_artists');

  const authToken = AuthService.getAuthToken();

  const [topArtists, setTopArtists] = useState();
  const [isFetching, setIsFetching] = useState();
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [timeRange, setTimeRange] = useState(TimeRanges.ranges.mediumTerm);
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);

  const colorThief = new ColorThief();

  const handleGetTopArtists = async () => {
    if(authToken && !hasNetworkError && !topArtists && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(topArtistsConfiguration(timeRange.queryParam));

      if(data.error) {
        setHasNetworkError(true);
        onNetworkError(data.error);
        return;
      }

      parseTopArtistData(data);
      setIsFetching(false);
    }
  };

  const parseTopArtistData = (data) => {
    const artistList = Artist.buildArtistListFromData(data);

    setTopArtists(artistList);
  };

  useEffect(() => {
    handleGetTopArtists();
  }, [handleGetTopArtists, authToken, timeRange]);

  const handleUpdateTimeRange = (timeRangeKey) => {
    logEvent(`top_artists_${timeRangeKey}_clicked`);
    setTimeRange(TimeRanges.ranges[timeRangeKey]);
    setTopArtists(null);
  };

  const handleShareClick = () => {
    logEvent('top_artists_share_clicked');

    setIsShowingShareModal(true);
  };

  const isLoaded = !!topArtists;

  return (
    <>
      <TimeRangePills
        onChange={(key) => handleUpdateTimeRange(key)}
        onClickShare={handleShareClick}
        selectedTimeRange={timeRange}
      />
      <div css={styles.outerContainer}>
        {topArtists && (
          <>
            {topArtists.map((artist, idx) => (
              <ColorCard
                colorThief={colorThief}
                imageUrl={artist.images[0]?.url}
                key={idx}
                rank={idx + 1}
                title={artist.name}
              />),
            )}
          </>
        )}
        {isShowingShareModal && (
          <ShareModal
            onHide={() => setIsShowingShareModal(false)}
            timeRange={timeRange}
            top={topArtists}
            type={'artist'}
          />
        )}
        {!isLoaded && <Loader />}
      </div>
    </>
  );
}

TopArtists.propTypes = {
  onNetworkError: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row wrap',
  },
});