import { StyleSheet } from '@root/utils/styles';
import { poppinsMedium } from '@root/utils/fonts';
import PropTypes from 'prop-types';
import React from 'react';

function Pill({
  onClick,
  href,
  isActive,
  children,
  className,
}) {
  const activeStyle = isActive ? styles.pillActive : null;

  return (
    <>
      {href && (
        <a
          className={className}
          css={[styles.pill, styles.pillLink, activeStyle]}
          href={href}
          onClick={onClick}
          rel={'noreferrer noopener'}
        >
          {children}
        </a>
      )}
      {!href && (
        <div
          className={className}
          css={[styles.pill, activeStyle]}
          data-is-active={isActive}
          onClick={onClick}
        >
          {children}
        </div>
      )}
    </>
  );
}

function PillButton({ onClick, isActive, children, className }) {
  return (
    <Pill
      className={className}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </Pill>
  );
}

function PillLink({ href, onClick, isActive, children, className }) {
  return (
    <Pill
      className={className}
      href={href}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </Pill>
  );
}

Pill.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  href: PropTypes.string,
  className: PropTypes.string,
};

PillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

PillLink.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  pill: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    border: '3px solid rgb(33, 37, 41)',
    lineHeight: '24px',
    borderRadius: 25,
    padding: 10,
    margin: 5,
    ':hover': {
      backgroundColor: 'rgb(33, 37, 41)',
      color: 'white',
    },
    ...poppinsMedium(),
  },
  pillLink: {
    color: 'rgb(33, 37, 41)',
    textDecoration: 'none',
    ':hover': {
      color: 'white',
    },
  },
  pillActive: {
    backgroundColor: 'rgb(33, 37, 41)',
    color: 'white',
  },
});

export { PillButton, PillLink };