import { StyleSheet } from '@root/utils/styles';
import { addSongsToPlaylistConfiguration, createPlaylistConfiguration } from '@root/api/spotify-configuration';
import AuthService from '@root/utils/auth-service';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import NetworkRequestController from '@root/utils/network-request-controller';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

export default function PlaylistExportButton({ songs, selectedTimeRange }) {
  const authToken = AuthService.getAuthToken();

  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);
  const [isShowingPlaylistModal, setIsShowingPlaylistModal] = useState(false);
  const [, setHasNetworkError] = useState(false);

  const playlistNameInputRef = useRef();

  const defaultPlaylistName = `My top songs ${selectedTimeRange.description} - ${(new Date()).toLocaleDateString('en-US')}`;

  const handleClickExportPlaylist = async () => {
    if(!isCreatingPlaylist) {
      setIsCreatingPlaylist(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const playlistName = playlistNameInputRef.current.value;
      const data = await networkRequestController.request(createPlaylistConfiguration(playlistName));

      if(data.error) {
        setHasNetworkError(true);
        return;
      }

      const playlistId = data.id;
      const songUris = songs.map((song) => song.uri);

      const addSongsResponse = await networkRequestController.request(addSongsToPlaylistConfiguration(playlistId, songUris));

      if(addSongsResponse.error) {
        setHasNetworkError(true);
        return;
      }

      setIsCreatingPlaylist(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsShowingPlaylistModal(false);
  };

  const handleOpenModal = () => {
    setIsShowingPlaylistModal(true);
  };

  useEffect(() => {
    if(isShowingPlaylistModal && playlistNameInputRef.current) {
      playlistNameInputRef.current.value = defaultPlaylistName;
    }
  }, [isShowingPlaylistModal]);

  return (
    <>
      <Button
        className={'ml-3 mb-3'}
        onClick={handleOpenModal}
        variant={'outline-dark'}
      >
        Export Playlist
      </Button>
      <Modal
        fullscreen={'true'}
        onHide={handleCloseModal}
        show={isShowingPlaylistModal}
      >
        <Container className={'p-3'}>
          <div css={styles.modalContainer}>
            <Form>
              <Form.Group className="mb-3" controlId="formPlaylistName">
                <div className={'d-flex justify-content-between mb-2'}>
                  <Form.Label>Playlist name</Form.Label>
                  <CloseButton
                    onClick={handleCloseModal}
                  />
                </div>
                <Form.Control disabled={isCreatingPlaylist} placeholder="Enter playlist name" ref={playlistNameInputRef} type="text" />
              </Form.Group>
              <Button
                disabled={isCreatingPlaylist}
                onClick={handleClickExportPlaylist}
              >
              Create Playlist
              </Button>
            </Form>
          </div>
        </Container>
      </Modal>
    </>
  );
}

PlaylistExportButton.propTypes = {
  songs: PropTypes.array.isRequired,
  selectedTimeRange: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  modalContainer: {
    margin: '0 auto',
    borderRadius: 15,
  },
});