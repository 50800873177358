import { followArtistConfiguration, getFollowingArtistConfiguration, unfollowArtistConfiguration } from '@root/api/spotify-configuration';
import { logEvent } from '@root/utils/analytics';
import AuthService from '@root/utils/auth-service';
import Button from 'react-bootstrap/Button';
import NetworkRequestController from '@root/utils/network-request-controller';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function FollowButton({
  artist,
}) {
  const authToken = AuthService.getAuthToken();

  const [isFollowing, setIsFollowing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasNetworkError, setHasNetworkError] = useState(false);

  const followArtist = async () => {
    if(authToken && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(followArtistConfiguration(artist.id));

      if(data.error) {
        setHasNetworkError(true);
        return;
      } else {
        setIsFollowing(true);
      }

      setIsFetching(false);
    }
  };

  const unFollowArtist = async () => {
    if(authToken && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(unfollowArtistConfiguration(artist.id));

      if(data.error) {
        setHasNetworkError(true);
        return;
      } else {
        setIsFollowing(false);
      }

      setIsFetching(false);
    }
  };

  const handleClick = () => {
    if(isFollowing) {
      logEvent('follow_button_unfollow_clicked');
      unFollowArtist();
    } else {
      logEvent('follow_button_follow_clicked');
      followArtist();
    }
  };
  useEffect(() => {
    if(authToken && !hasNetworkError && !hasFetched && !isFetching) {
      setShouldFetch(true);
    }
  }, [authToken, hasFetched]);

  useEffect(() => {
    if(shouldFetch) {
      setShouldFetch(false);
      handleGetFollowStatus();
    }
  }, [shouldFetch]);

  const handleGetFollowStatus = async () => {
    setIsFetching(true);

    const networkRequestController = new NetworkRequestController(authToken);

    const data = await networkRequestController.request(getFollowingArtistConfiguration(artist.id));

    if(data.error) {
      setHasNetworkError(true);
      return;
    }

    setIsFollowing(data[0]);
    setIsFetching(false);
    setHasFetched(true);
  };


  const getButtonText = () => {
    if(isFetching) {
      return '...';
    }
    if(!isFollowing){
      return 'Follow';
    }
    if(isHovered){
      return 'Unfollow?';
    }
    return 'Following';
  };

  const buttonVariant = isFetching || isFollowing ? 'outline-dark' : 'dark';

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Button
      disabled={isFetching}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variant={buttonVariant}
    >
      {getButtonText()}
    </Button>
  );
}

FollowButton.propTypes = {
  artist: PropTypes.object.isRequired,
};