import { StyleSheet } from '@root/utils/styles';
import { logEvent } from '@root/utils/analytics';
import { poppinsMedium } from '@root/utils/fonts';
import React from 'react';

export default function Footer() {
  const handleClick = () => {
    logEvent('footer_created_by_clicked');
  };

  return (
    <div css={styles.container}>
      <p>Created by&nbsp;
        <a
          css={styles.link}
          href={'https://tylerkucera.com/'}
          onClick={handleClick}
          rel={'noreferrer noopener'}
          target={'_blank'}
        >
          Tyler Kucera
        </a>
      </p>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    color: 'rgb(33, 37, 41)',
    display: 'flex',
    justifyContent: 'center',
    ...poppinsMedium(),
  },
  link: {
    color: 'rgb(33, 37, 41)',
    ':hover': {
      color: 'rgb(33, 37, 41)',
    },
  },
});