import { PillButton } from '@root/components/pill';
import { StyleSheet } from '@root/utils/styles';
import NavSeparator from '@root/components/nav-separator';
import PropTypes from 'prop-types';
import React from 'react';
import ShareIcon from '@root/components/share-icon';
import TimeRanges from '@root/models/time-ranges';

export default function TimeRangePills({ onChange,  onClickShare, selectedTimeRange }) {
  return (
    <>
      <NavSeparator />
      <div css={styles.pillContainer}>
        {Object.keys(TimeRanges.ranges).map((key) => {
          const item = TimeRanges.ranges[key];

          return (
            <PillButton
              isActive={selectedTimeRange.key === item.key}
              key={key}
              onClick={() => onChange(item.key)}
            >
              {item.title}
            </PillButton>
          );
        })}
        {onClickShare && (
          <PillButton
            data-testid={'share-button'}
            onClick={onClickShare}
          >
            <ShareIcon />
          </PillButton>
        )}
      </div>
      <NavSeparator />
    </>
  );
}

TimeRangePills.propTypes = {
  onClickShare: PropTypes.func,
  onChange: PropTypes.func,
  selectedTimeRange: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  pillContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
});