import { StyleSheet } from '@root/utils/styles';
import { logEvent, logView } from '@root/utils/analytics';
import { topSongsConfiguration } from '@root/api/spotify-configuration';
import AuthService from '@root/utils/auth-service';
import ColorCard from '@root/components/color-card';
import ColorThief from 'colorthief/dist/color-thief.umd.js';
import Loader from '@root/components/loader';
import NetworkRequestController from '@root/utils/network-request-controller';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ShareModal, { shareModalTypes } from '@root/components/share-modal';
import Song from '@root/models/song';
import TimeRangePills from '@root/components/time-range-pills';
import TimeRanges from '@root/models/time-ranges';

export default function TopSongs({ onNetworkError }) {
  logView('top_songs');

  const authToken = AuthService.getAuthToken();

  const [topSongs, setTopSongs] = useState();
  const [isFetching, setIsFetching] = useState();
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [timeRange, setTimeRange] = useState(TimeRanges.ranges.mediumTerm);
  const [isShowingShareModal, setIsShowingShareModal] = useState(false);

  const colorThief = new ColorThief();

  const handleGetTopSongs = async () => {
    if(authToken && !hasNetworkError && !topSongs && !isFetching) {
      setIsFetching(true);

      const networkRequestController = new NetworkRequestController(authToken);

      const data = await networkRequestController.request(topSongsConfiguration(timeRange.queryParam));

      if(data.error) {
        setHasNetworkError(true);
        onNetworkError(data.error);
        return;
      }

      parseTopSongData(data);
      setIsFetching(false);
    }
  };

  const parseTopSongData = (data) => {
    const songList = Song.buildSongListFromData(data);

    setTopSongs(songList);
  };

  useEffect(() => {
    handleGetTopSongs();
  }, [handleGetTopSongs, authToken, timeRange]);

  const handleUpdateTimeRange = (timeRangeKey) => {
    logEvent(`top_songs_${timeRangeKey}_clicked`);
    setTimeRange(TimeRanges.ranges[timeRangeKey]);
    setTopSongs(null);
  };

  const handleShareClick = () => {
    logEvent('top_songs_share_clicked');

    setIsShowingShareModal(true);
  };

  const isLoaded = !!topSongs;

  return (
    <>
      <TimeRangePills
        onChange={(key) => handleUpdateTimeRange(key)}
        onClickShare={handleShareClick}
        selectedTimeRange={timeRange}
      />
      <div css={styles.outerContainer}>
        {topSongs && (
          <>
            {topSongs.map((song, idx) => (
              <ColorCard
                colorThief={colorThief}
                imageUrl={song.album.images[0]?.url}
                key={idx}
                rank={idx + 1}
                subtitle={song.artists.map((artist) => artist.name).join(', ')}
                title={song.name}
              />),
            )}
          </>
        )}
        {isShowingShareModal && (
          <ShareModal
            onHide={() => setIsShowingShareModal(false)}
            timeRange={timeRange}
            top={topSongs}
            type={shareModalTypes.song}
          />
        )}
        {!isLoaded && <Loader />}
      </div>
    </>
  );
}

TopSongs.propTypes = {
  onNetworkError: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row wrap',
  },
});